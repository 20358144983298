.container {
  background-color: black;
  width: 99.9vw;
  height: 99.8vh;
  /* border: 1px solid white; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* box-shadow: 2px 10px 10px rgba(255, 255, 255, 0.742); */
}
.innerContainer {
  /* border: 1px solid yellow; */
  /* background-color: yellow; */
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12%;
}
.innerContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.vector {
  height: 80vh;
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.vector img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.circle {
    width: 99.9vw;
    height: 70%;
  background: radial-gradient(circle, #ffffff37 30%, transparent 70%);

  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid black;
}
.contentSectionNew {
  /* border: 1px solid red; */
  position: absolute;
  bottom: 3%;
  width: 99.8%;
  display: flex;
  flex-direction: column;
  gap: 5%;
  align-items: center;
  /* justify-content: space-between; */
  color: white;
  height: 38vh;
}
.contentSectionNew img {
  width: 40%;
  animation: slideDown 1s ease-out forwards 0.1s;
  opacity: 0;
}
.primaryText {
  font-size: 1rem;
  animation: slideDown 1s ease-out forwards 1s;
  opacity: 0;
}
.secondaryText {
  font-size: 0.8rem;
  animation: slideDown 1s ease-out forwards 1s;
  opacity: 0;
}
.contentSectionNew button {
  width: 90%;
  padding: 12px;
  border-radius: 10px;
  color: black;
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(180deg, #dbdbdb 0%, #656565 100%);
}
@media screen and (min-width: 601px) {
  .container,
  .vector {
    width: 20rem;
    max-width: 20rem;
    height: 95vh;
  }
  .innerContainer {
    max-width: 20rem;
  }
  .innerContainer img{
    max-width: 100%;
    min-width: 100%;
    object-fit: contain !important;
  }
  .contentSectionNew {
    width: 19.9rem;
    bottom: 10%;
  }
  .circle{
    width: 20rem;
  }
  .primaryText{
    font-size: 16px;
  }
}

@keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  