/* Mobile-first styles */
.terms-container {
    padding: 1rem;
    font-size: 0.9rem;
    line-height: 1.5;
    color: white;
    overflow-y: scroll;
    box-sizing: border-box;
    background-color:#202020;

  }
  
  .back-btn {
    background: linear-gradient(180deg, #DBDBDB 0%, #656565 100%);
    color: black;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;
    display: block;
    margin: auto;
    width: 90%;
    font-weight: bold;
  }
  
  .back-btn:hover {
    background-color: #0056b3;
  }
  
  .terms-container h2, .terms-container h3 {
    font-weight: bold;
    color: #f2f2f2; /* A slightly lighter shade of white for contrast */
  }
  
  .terms-container h2 {
    font-size: 1.5rem; /* Larger size for h2 */
    margin-top: 2rem; /* Increase space above h2 */
    margin-bottom: 1rem; /* Increase space below h2 */
  }
  
  .terms-container h3 {
    font-size: 1.25rem; /* Slightly smaller size for h3 */
    margin-top: 1.75rem; /* Increase space above h3 */
    margin-bottom: 0.75rem; /* Increase space below h3 */
  }
  
  .terms-container p {
    margin-bottom: 1.5rem; /* Increase space between paragraphs */
    color: #e6e6e6; /* Slightly lighter text color for readability */
    font-weight: 500;
  }
  .terms-container strong{
    font-weight: 700;
    color: white;
  }
  
  .terms-container::-webkit-scrollbar {
    display: none !important;
  }
  
   /* Tablet and Desktop styles */
   @media (min-width: 768px) {
    .terms-container {
      width: 21.8rem;
      margin: 0 auto;
      font-size: 1rem;
      overflow-y: auto;
      max-height: 100vh; /* Ensures container is scrollable if content overflows */
      border: 1px solid;
      border-radius: 15px;
      padding-top: 1rem;
      padding-bottom: 2rem;
      box-sizing: border-box;
    }
  }
  