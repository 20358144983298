#inputWrapper {
  padding: 1px; /* Adjust this to control border thickness */
  border-radius: 6px; /* Slightly larger than inner div to account for border */
  background: repeating-linear-gradient(
    45deg,
    #f5f5f5 0%,
    /* Off-white */ #a9a9a9 8.33%,
    /* Medium grey */ #575757 16.66%,
    /* Darker grey */ #a9a9a9 24.99%,
    /* Medium grey */ #f5f5f5 33.32%,
    /* Off-white */ #f5f5f5 33.33% /* Start of next repeat */
  );
  /* 6 times the size of one complete pattern */
  background-position: 0 0; /* Keep it static */
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.4), 0 0 2px rgba(255, 255, 255, 0.3);
}
#inputWrapper div {
  transition: background-color 0.3s linear;
}
@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 200%;
  }
}
/* .inputWrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    transition: 0.5s;
}

.inputWrapper:hover::after {
    left: 100%;
} */

#myInput {
  display: flex;
  gap: 5px;
  padding: 10px;
  /* background-color: black; */
  border-radius: 5px;
}
@font-face {
  font-family: "AvenirLTStd65Medium"; /* Give the font a name */
  src: url("./assets/fonts/Avenir/Avenir Light/Avenir\ Light.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
#regContainer {
  overflow-y: scroll !important;
  /* border: 1px solid white; */
  max-height: 100vh !important;
  position: relative;
}
#regContainer ::-webkit-scrollbar {
  display: none !important;
  min-height: 110vh;
}

@media (min-width: 768px) {
  .custom-height {
    /* height: 95vh !important; */
    border-radius: 1rem; /* equivalent to md:rounded-xl */
  }
  .custom-height::-webkit-scrollbar {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .custom-height {
    height: 100vh;
  }
  .logo {
    margin-top: 30px;
  }
  .regText {
    margin: 30px auto !important;
  }
  .formSection {
    margin-top: 30px !important;
  }
  @media screen and (min-width: 426px) and (max-width: 539px) {
    .logo {
      margin-top: 30px;
      width: 10rem !important;
    }

    .regText {
      width: 15rem !important;
      margin: 40px auto !important;
    }
    .welcomeText {
      font-size: 18px !important;
    }
    .formSection {
      gap: 15px !important;
      margin-top: 40px !important;
    }
    #inputWrapper #myInput {
      height: 3rem;
    }

    /* #myInput img{
  width: 10%;
 } */
  }
}

.errorPopup {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 22rem;
  background-color: white; /* Example color */
  color: black;
  padding: 10px;
  text-align: center;
  z-index: 1000;
  height: 30vh;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 20px;
}
.errorPopup img {
  width: 5%;
  cursor: pointer;
}
.errorPopupHeader {
  /* border: 1px solid red; */
  width: 100%;
  font-weight: bolder;
  font-size: 20px;
}
.errorPopupText{
  /* border: 1px solid green; */
  margin-top: 20px;
  width: 100%;

}
