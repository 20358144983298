.inputContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Figtree", sans-serif;
}
.inputContainer input{
    width: 3rem;
    height: 3rem;
    border: 1px solid rgba(0, 0, 0, 0.139);
  display: flex;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
 
  align-items: center;
  gap: 5px;
  /* border: 1px solid wheat; */
}

#inputWrapper {
  padding: 1px; /* Adjust this to control border thickness */
  margin: 6px;
  border-radius: 6px; /* Slightly larger than inner div to account for border */
  background: repeating-linear-gradient(
    45deg,
    #F5F5F5 0%,    /* Off-white */
    #A9A9A9 8.33%,   /* Medium grey */
    #696969 16.66%, /* Darker grey */
    #A9A9A9 24.99%, /* Medium grey */
    #F5F5F5 33.32%, /* Off-white */
    #F5F5F5 33.33%  /* Start of next repeat */
  );
/* 6 times the size of one complete pattern */
  background-position: 0 0; /* Keep it static */
  box-shadow:
    0 0 2px rgba(255, 255, 255, 0.4),
    0 0 2px rgba(255, 255, 255, 0.3);
}